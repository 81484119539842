<template>
  <div id="orderproduct-list">
    <v-card>
      <v-card-title>注文実績一覧(単品)</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col cols="12" md="2">
          <v-autocomplete
            v-model="item_code"
            :items="itemCodes"
            placeholder="商品コード"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="firstName"
            placeholder="セイ"
            outlined
            clearable
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="lastName"
            placeholder="メイ"
            outlined
            clearable
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start_date"
                placeholder="From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="start_date" @input="dateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="To"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-btn
          color="primary"
          class="ml-auto mr-4"
          @click="conditionalSearch"
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiMagnify }}
          </v-icon>
          <span>検索</span>
        </v-btn>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'order-create', params: { query: this.$route.query } }"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiClipboardArrowDownOutline }}
            </v-icon>
            <span>DownLoad</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="tableList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              > {{ item.mbr_sei }} {{ item.mbr_mei }}</span>
            </template>
            <span>e-mail</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.order_dt`]="{ item }">
          <div>
            <span>{{ item.order_dt }}</span>
          </div>
        </template>
        <template v-slot:[`item.os_desc`]="{ item }">
          <div>
            {{ item.os_desc }}
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row v-if="!item.processed">
            <v-col style="padding-right: 10px" cols="6">
              <v-icon class="mr-2" small @click="editItem(item)">
                {{ icons.mdiPencil }}
              </v-icon>
            </v-col>
            <v-col style="padding-left: 10px" cols="6">
              <delete-form :icon="icons.mdiDelete" :crushed-id="item.crushed_id"></delete-form>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.order_code`]="{ item }">
          <v-tooltip v-if="item.order_code" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              > {{ item.order_code }}</span>
            </template>
            <span>{{ item.type }}<br />{{ item.product_name }}<br />{{ item.price }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiCalendar,
  mdiMagnify,
  mdiPencil,
  mdiDelete,
  mdiExportVariant,
  mdiAutorenew,
  mdiPlus,
  mdiClipboardArrowDownOutline,
  mdiClipboardArrowUpOutline,
} from '@mdi/js'
import DeleteForm from '@/views/bimi/product/item/ProductsDelete.vue'

export default {
  components: {
    DeleteForm,
  },
  data: () => ({
    start_date: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    firstName: '',
    lastName: '',
    item_code: '',
    arrBfo: [],
    search: '',
    tableList: [],
    icons: {
      mdiCalendar,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiAutorenew,
      mdiPlus,
      mdiClipboardArrowDownOutline,
      mdiClipboardArrowUpOutline,
    },
    headers: [
      {
        text: '注文ID',
        value: 'ord_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },

      {
        text: '注文番号',
        value: 'order_no',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: '注文番号',
        value: 'order_no',
        align: ' d-none',
      },
      {
        text: '会員氏名',
        align: 'left',
        sortable: true,
        value: 'name',
        width: '10%',
        fixed: true,
      },

      // {
      //   text: '商品名',
      //   value: 'product_name',
      //   width: '20%',
      //   align: 'left',
      //   sortable: true,
      //   fixed: false,
      // },

      {
        text: '商品名',
        value: 'mbr_mobile',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },

      // {
      //   text: '注文総額',
      //   value: 'order_total',
      //   width: '10%',
      //   align: 'right',
      //   sortable: true,
      //   fixed: true,
      // },
      {
        text: '注文日',
        value: 'order_dt',
        width: '20%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '注文状態',
        value: 'os_desc',
        width: '10%',
        align: 'center',
        sortable: true,
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
    ],
  }),
  computed: {
    ...mapState('orderStore', ['orderList', 'orderStatusDesc']),
    ...mapState('itemStore', ['itemCodes']),
  },
  watch: {
  },
  created() {
    this.loadData()
    this.loadItemCodes()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('orderStore', ['loadOrderList', 'loadOrderStatus']),
    ...mapActions('itemStore', ['loadItemCodes']),

    loadData() {
      this.setOverlayStatus(true)
      this.loadOrderList(this.$route.query).then(() => {
        // this.saleableStatus=[]
        this.tableList = [...this.orderList]
        this.setOverlayStatus(false)
        console.log('loadData', this.orderList)
      })
      this.loadOrderStatus(this.$route.query).then(() => {
        // this.saleableStatus=[]
        this.setOverlayStatus(false)
        console.log('loadData', this.orderStatusDesc)
      })
    },

    editItem(item) {
      this.$router.push({
        name: 'order-edit',
        params: {
          id: item.crushed_id, query: this.$route.query,
        },
      })
    },

    conditionalSearch() {
      const query = {
      }
      this.$router.push({
        path: this.$route.path, query,
      })
      this.loadData()
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
