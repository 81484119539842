var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"orderproduct-list"}},[_c('v-card',[_c('v-card-title',[_vm._v("注文実績一覧(単品)")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemCodes,"placeholder":"商品コード","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.item_code),callback:function ($$v) {_vm.item_code=$$v},expression:"item_code"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"placeholder":"セイ","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"placeholder":"メイ","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"From","dense":"","hide-details":"","outlined":"","readonly":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateStartMenu),callback:function ($$v) {_vm.dateStartMenu=$$v},expression:"dateStartMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateStartMenu = false}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"To","dense":"","outlined":"","readonly":"","hide-details":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateEndMenu),callback:function ($$v) {_vm.dateEndMenu=$$v},expression:"dateEndMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateEndMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-btn',{staticClass:"ml-auto mr-4",attrs:{"color":"primary"},on:{"click":_vm.conditionalSearch}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMagnify)+" ")]),_c('span',[_vm._v("検索")])],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","to":{ name: 'order-create', params: { query: this.$route.query } }}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("新規")])],1),_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClipboardArrowDownOutline)+" ")]),_c('span',[_vm._v("DownLoad")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableList,"sort-by":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.mbr_sei)+" "+_vm._s(item.mbr_mei))])]}}],null,true)},[_c('span',[_vm._v("e-mail")])])]}},{key:"item.order_dt",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.order_dt))])])]}},{key:"item.os_desc",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.os_desc)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.processed)?_c('v-row',[_c('v-col',{staticStyle:{"padding-right":"10px"},attrs:{"cols":"6"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1),_c('v-col',{staticStyle:{"padding-left":"10px"},attrs:{"cols":"6"}},[_c('delete-form',{attrs:{"icon":_vm.icons.mdiDelete,"crushed-id":item.crushed_id}})],1)],1):_vm._e()]}},{key:"item.order_code",fn:function(ref){
var item = ref.item;
return [(item.order_code)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.order_code))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.type)),_c('br'),_vm._v(_vm._s(item.product_name)),_c('br'),_vm._v(_vm._s(item.price))])]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }